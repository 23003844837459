.dropdown-wrapper {
  position: relative;
  width: -webkit-fill-available;
}

.not-working-dropdown-wrapper {
  position: relative;
  width: -webkit-fill-available;
  top: -50px;
}

.dropdown-wrapper .dropdown {
  background: #fff;
  width: 100%;
  height: 100px;
  position: absolute;
  top: 50px;
  left: 0;
  font-family: "Open Sans";
  font-size: 22px;
  display: flex;
  align-items: center;
}

ul::-webkit-scrollbar {
  display: none;
}

ul {
  background: #fff;
  color: #000000;
  list-style: none;
  margin: 0;
  font-size: 14px;
  overflow: hidden;
  height: 0px;
  transition: height 0.3s ease;
  padding: 0;
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate3d(0px, 40px, 0px);
  width: 200px;
  border-radius: 5px;
  margin-top: 5px;
}

ul.active {
  height: 120px;
  overflow: auto;
  background: white;
  width: 100%;
  z-index: 100;
}

li {
  margin-bottom: 2px;
  background: white;
}

li:hover {
  font-size: 15px;
  cursor: pointer;
}
