.pulse {
  display: block;
  border-radius: 50%;
  cursor: pointer;
  animation: pulse 2s infinite;
}

.tooltip-circle-label {
  box-shadow: none !important;
  background: transparent !important;
  border: transparent !important;
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 0 0;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
