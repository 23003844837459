.ms-Dialog-title {
  /* background-color: #f3f4f3 !important; */
  display: none;
}

.ms-Overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.ms-Dialog-main {
  box-shadow: none;
}
