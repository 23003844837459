@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.card__image {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 10px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
.card__content {
  padding: 20px;
}
