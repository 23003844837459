.releaseBtn {
  background-color: #f1f1f2;
  color: gray;
  transition: background-color 0.2s ease;
}

.releaseBtn:hover {
  color: white !important;
  background-color: #3e97ff !important;
}
