.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.inner-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid white;
}
