.trigger-button {
  padding: 10px;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
}

.trigger-button:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
}

.dropdown-wrapper {
  position: relative;
  width: 100%;
}

.dropdown-wrapper .dropdown {
  background: #fff;
  width: 100%;
  height: 120px;
  position: absolute;
  top: 50px;
  left: 0;
  font-family: "Open Sans";
  font-size: 14px;
  display: flex;
  align-items: center;
}

ul {
  background: #fff;
  color: #000000;
  list-style: none;
  margin: 0;
  font-size: 14px;
  overflow: hidden;
  height: 0px;
  transition: height 0.3s ease;
  padding: 0;
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate3d(0px, 40px, 0px);
  width: 200px;
  border-radius: 5px;
  margin-top: 5px;
}

ul.active {
  height: 120px;
  overflow: auto;
  background: white;
  width: 100%;
  z-index: 100;
  border: 1px solid #c5c5c5;
}

li {
  margin-bottom: 2px;
  background: white;
  text-align: left;
  padding: 3px 10px;
}

li:hover {
  font-size: 15px;
  cursor: pointer;
}
