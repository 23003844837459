/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

@media all and (min-width: 600px) {
  .deskContent {
    display: initial;
  }

  .phoneContent {
    display: none;
  }
}

@media all and (max-width: 599px) {
  .deskContent {
    display: none;
  }

  .phoneContent {
    display: initial;
    background: #e7f4f6;
  }

  body {
    background: #e7f4f6;
  }
}

body,
html {
  font-family: Roboto, Helvetica, sans-serif;
  margin: 0 !important;
  /* 👇️ or use Operating system default fonts 👇️ */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.ui-provider {
  background-color: transparent !important;
}

.title-108 {
  padding-right: 0 !important;
}

.title-175 {
  padding-right: 0 !important;
}

#currentDatePicker {
  width: 160px;
  padding-top: 4px;
}

#currentDatePicker2 {
  margin-top: 10px;
}

#currentDatePicker-label {
  margin-right: 15px;
  position: relative;
}

#no-content-message-bar {
  width: 300px;
  margin: auto;
}

#no-plan-message-bar {
  width: 300px;
}

.hover:hover {
  cursor: pointer;
}

.ms-Dialog-title {
  background-color: #4052ab;
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  width: 100%;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-item {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  transition: 0.3s;
  border-radius: 10px;
  width: 100%;
}

/* On mouse-over, add a deeper shadow */
.card-item:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
}

/* Add some padding inside the card container */
.container {
  padding: 16px;
  width: auto;
}

.spin-icon {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.card-stack {
  background-color: #ffffff;
  min-height: 90px;
  border: 3px solid #5b5b5b;
  padding: 15px;
  box-shadow: 10px -10px 0 -3px #ffffff, 10px -10px #5b5b5b;
  transition: box-shadow 1s, top 1s, left 1s;
  cursor: pointer;
  border-radius: 10px;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.daysie-calendar .ms-TextField-fieldGroup {
  border-radius: 5px;
}

.nav-menu-selected path {
  stroke: #38818d;
  fill: #38818d;
}

.ms-Persona-imageArea {
  cursor: pointer !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
/* * {
    scrollbar-width: auto;
    scrollbar-color: #d8d8d8 #ffffff;
  } */

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
  border: 3px solid #ffffff;
} */

/* make scrollbar invisible */
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
